/**
 * Affiche le loader de la page
 */
var _loading = null
export var _pageLoading = function (i = true) {
  if (i) {
    Pace.restart()
    $.when($('#page-container').css('opacity', '0.35')).done(function () {
      // $('.pace').addClass('in').removeClass('pace-inactive').removeClass('hide')
      $('#page-loader').css('opacity', '1').css('background', 'transparent').removeClass('d-none')
    })
  } else {
    $.when($('#page-container').css('opacity', '1')).done(function () {
      // $('.pace').removeClass('in').addClass('pace-inactive').addClass('hide')
      $('#page-loader').css('opacity', '0').addClass('d-none')
    })
  }
}

export var _pagePending = function (i = true) {
  if (i) {
    $.when($('#page-container').css('opacity', '0.2')).done(function () {
      $('#page-loader').css('opacity', '1').css('background', 'transparent').removeClass('d-none')
      _loading = $('#page-loader').html()
      $('#page-loader').html('<div class="check_mark">\
        <div class="sa-icon sa-success animate">\
          <span class="sa-line sa-tip animateSuccessTip"></span>\
          <span class="sa-line sa-long animateSuccessLong"></span>\
          <div class="sa-placeholder"></div>\
          <div class="sa-fix"></div>\
        </div>\
      </div>')
    })
  } else {
    $.when($('#page-container').css('opacity', '1')).done(function () {
      $('#page-loader').css('opacity', '0').addClass('d-none')
      $('#page-loader').html(_loading)
    })
  }
}

/**
 * Récupère l'url courante depuis un niveau de profondeur donné sinon l'url courante entière (nécessite domurl https://github.com/Mikhus/domurl)
 * @param {number} level = -1 Niveau de profondeur
 * @return {string} Url courante
 */
export var _getUrl = function (level = -1) {
  var u = new Url() // curent document URL will be used
  if (level >= 0) {
    var paths = u.paths()
    var new_url = '/'
    for (var i = 0; i <= level - 1; i++) {
      new_url += paths[i] + '/'
    }
    var u2 = new Url(new_url)
    var url2 = u2.toString()
    return url2.substring(0, url2.length - 1)
  } else {
    return u.toString()
  }
}
